@use "variables";
@import "@syncfusion/ej2-icons/styles/bootstrap";

* {
  transition: background-color 200ms linear;
}

body {
  box-sizing: border-box;
}

#viewer-host {
  width: 100%;
  height: 100%;
}

#designer-host {
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}



a {
  text-decoration: none !important;
}

.MuiListSubheader-root {
  background-color: #061a25 !important;
  opacity: 1;
}

.MuiContainer-root {
  padding: 0 !important;
}

.MuiList-padding {
  padding: 0 !important;
}

.MuiCalendarPicker-root {
  background: #2f4756 !important;
}

.css-mvmu1r div button{
  background: #2f4756 !important;
}

.css-yz9k0d-MuiInputBase-input{
  height: 36px !important;
}

.MuiInputBase-input, .MuiOutlinedInput-root {
  border-radius: 4px;
}

.layout {
  position: relative;
  top: 6px;
  left: 6px;
}

#charts_border {
  fill: transparent !important;
}

.scrollbar-container {
  margin-top: 48px !important;
}

/* Component styles */

.main-wrapper {
  //background-color: #fff;
  color: #000;
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
}

.mapping-wrapper {
  background-color: #fff;
  color: #000;
  flex-grow: 1;
  height: 100vh;
}

/* Panel styles */

.panel-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
}

.panel {
  width:15.5%;
  min-width: 240px;
  height: 180px;
  margin-right: 15px;
  margin-bottom: 15px;
  background: #2e4857;
  border-radius: 5px;
  box-shadow: 10px 5px 10px #0b2a3c;
}

/* .panel-header {
  display: flex;
  justify-content: space-between;
  padding: 7px 18px;
  background: #233c4e;
  border-radius: 5px 5px 0 0;
} */

.panel-header__content {
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  cursor: all-scroll;
}

.panel-header__icon {
  cursor: pointer;
}

.panel-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 75%;
  padding-left: 18px;
} 

.panel-content__description {
  margin: 0;
  font-size: 14px;
}

/* .e-toolbar-items {
  width: 100% !important;
}

.e-toolbar-item {
  width: 50% !important;
} */

#switch-list-label-wifi {
  width: 8px;
}

.e-dashboardlayout.e-control .e-panel{
  border: 1px #bbd2ec solid;
}

.e-dashboardlayout.e-control .e-panel:hover {
  border: 1px #bbd2ec solid;
}

#api_dashboard.e-dashboardlayout.e-control .e-panel .e-panel-header {
  background-color: #bbd2ec !important;
  color: #231f1f;
}

//[data-type="rendered-map"] > div {
//  width: fit-content !important;
//  height: fit-content !important;
//}

#api_property .form-label {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.5;
  font-size: 13px;
  font-weight: 500;
}

#api_property .row .e-btn {
  display: inline;
}

#api_dashboard {
  padding: 10px;
  width: 100%;
}
#api_dashboard .panel-content {
  vertical-align: middle;
  font-size: 15px;
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.61);
  text-align: center;
}

#default_dashboard .e-panel {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

#api_dashboard .e-panel-content {
  height: calc(100% - 28px);
  width: 100%;
  display: block;
}

#api_dashboard .e-panel .e-header-text {
  padding: 12px 0 12px 0;
}

#api_dashboard.e-dashboardlayout.e-control .e-panel .e-panel-header {
  font-size: 15px;
  font-weight: 500;
  height: 37px;
  padding: 10px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, .03);
  border-bottom: 0.5px solid #0d6251;
}

.e-dashboardlayout.e-control .e-panel .e-panel-header {
  padding-top: 0;
  padding-bottom: 0;
}

#api_property .row {
  margin-left: 0px;
  margin-right: 0px;
}

#api_property .row-header {
  font-size: 13px;
  font-weight: 500;
}

#api_dashboard #reset {
  display: block;
  margin: 0 auto;
  width: 90px;
}

.property-section.dashboard .e-input-group.e-control-wrapper {
  width:122px;
}

.e-treeview .e-list-text { 
  font-size: 16px; 
  font-weight: bold;
} 

.e-treeview.e-fullrow-wrap .e-text-content{
  padding: 0;
}

.e-control {
  border: none;
}

.map-wrapper {
  position: relative;
  display: inline-block;
  margin: 0;
  height: 100%;
  //width: 100%;
}

.map-content {
  height: 100%;
  border: 2px solid rgba(0,0,0,0.2);
}

.syn-map{
  height: 100%;
  width: 100%;
}

.syn-map-actions{
  z-index: 500;
  position: absolute;
  left: 10px;
  top: 70px;
  margin-top: 10px;
  background: white;
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
  border-radius: 4px;
  width: 34px;
  height: 34px;
}

.static-map-actions{
  z-index: 500;
  position: absolute;
  left: 10px;
  margin-top: 10px;
  background: white;
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
  border-radius: 4px;
  width: 30px;
  height: 30px;
}

.syn-map-actions >button{
  padding: 8px;
  line-height: 26px;
  cursor: pointer;
}

.static-map-actions >button{
  padding: 5px;
  line-height: 26px;
  cursor: pointer;
}

.save-map-btn{
  z-index: 500;
  position: absolute;
  left: 10px;
  top: 80px;
  background-color: white;
  /*background-color: #1976d2;*/
  /*color: white;*/
  border-radius: 5px;
  /*border-color: #1976d2;*/
  border-color: white;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}

.map-regions-checkbox-wrapper{
  z-index: 500;
  position: absolute;
  right: 200px;
  margin-top: 90px;
  background: white;
  border: 2px solid rgba(0,0,0,0.2);
  border-radius: 4px;
  background-clip: padding-box;
  width: 212px;
  padding: 0 7px;
  overflow: auto;
}

.map-types-dropdown {
  z-index: 500;
  position: absolute;
  right: 200px;
  padding: 0 7px 5px 7px;
  //margin-top: 10px;
  background: white;
  border: 2px solid rgba(0,0,0,0.2);
  border-radius: 4px;
  margin-top: -10px;
  width: 90%;
}

#mapCustom {
  height: 100%;
}

.dynamic-forms .e-input {
  font-size: 13px !important;
}

.e-treeview .e-list-text {
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  color: black;
}

.dropdown-value-clean-btn {
  margin-left: 5px;
  font-size: 15px;
  border-radius: 50%;
  background-color: #1976d2;
  border: 1px solid #1976d2;
  width: 25px;
  height: 25px;
  color: white;
  padding: 0;
}

.MuiFormControlLabel-label {
  /*font-weight: 400;*/
  font-size: 13px !important;
  /*line-height: 1.5;*/
  /*letter-spacing: 0.00938em;*/
}

.MuiFormControlLabel-root {
  margin-top: 0!important;
}

/*.MuiButtonBase-root-MuiCheckbox {*/
/*  padding: 5px !important;*/
/*}*/

.default .e-menu-item {
  background-color: #bbd2ec !important;
}

.green .e-menu-item {
  background-color: #1abc9c !important;
}

.yellow .e-menu-item {
  background-color: #f6e58d !important;
}

.defaultdark .e-menu-item {
  background-color: #2a2a2a !important;
}

.greendark .e-menu-item {
  background-color: #0b6047 !important;
}

.yellowdark .e-menu-item {
  background-color: #f9ca24 !important;
}

.e-menu-wrapper {
  border: none;
}

.default .e-menu-item:hover {
  background-color: #5e9de0 !important;
}

.green .e-menu-item:hover {
  background-color: #2ecc71 !important;
}

.yellow .e-menu-item:hover {
  background-color: #2ecc71 !important;
}

.e-pivot-toolbar {
  border: none !important;
}

button.report-button {
  display: flex;
  align-items: center;
  height: 16.797px;
  background-color: #bbd2ec;
}

.css-1ez4img-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
  z-index: 1201 !important;
}

#root > div .css-1fqcua4-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
  z-index: 100 !important;
}

header {
  box-shadow: none !important;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.row > * {
  padding-right: 0px;
  padding-left: 0px;
}

.e-pivotview .e-pivotchart>svg {
  border-bottom: none;
  border-right: none;
  border-left: none;
}

.grid-item {
  border: 1px solid #E0E0E0;
}

 .grid-item-rendered-map {
   height: fit-content !important;
   width: fit-content !important;
 }

.content-grid {
  width: 100%;
  height: 100%;
  font-size: 100%; /* Adjust the font size as needed */
}

.draggablePanelHeader {
  cursor: grab;
}

.css-3tdvxv-MuiToolbar-root, .css-16g7hxx-MuiToolbar-root, .css-hyum1k-MuiToolbar-root, .css-11t8wa6-MuiToolbar-root  {
  min-height: 50px !important;
}

.sidebar-menu {
  background: #bbd2ec;
  height: 64px;
  width: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  position: fixed;
  left: 0;
  bottom: 0;
}

// .e-fullrow {
//   //background: white !important;
// }

#mainTree[data-theme="dark"] {
   & span {
     color: #fff;
   }

   .e-hover > .e-fullrow {
     background: #525555 !important;
     border-color: #525555 !important;
   }

  .e-icon-expandable {
    color: white;
  }

  .e-icon-collapsible {
    color: white;
  }
}

#mainTree {
  margin-bottom: 80px;
}

#mainTree[data-theme="white"] {
  & span {
    color: #000;
  }

  .e-hover > .e-fullrow {
    background: #f5f5f5 !important;
    border-color: #f5f5f5 !important;
  }

  .e-icon-expandable {
    color: black;
  }

  .e-icon-collapsible {
    color: black;
  }
}

.e-treeview .e-list-item.e-active>.e-text-content .e-list-text {
  color: #fff !important;
}

.sidebar-menu-settings {
  cursor: pointer;
}

.sss {
  background: white;
  border-radius: 4px;
  padding: 4px;
}

.input-manage-regions {
  width: 100%;
  margin-top: 8px;
  padding: 6px 2px;
  border-radius: 4px;
}
.custom-toast-position {
  top: 60px; /* Adjust the pixel value as needed */
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  min-width: 240px !important;
  min-height: 320px !important;
}

.footer-menu-top {
  background: #bbd2eb;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// .e-dialog .e-footer-content .e-edit-dialog{
//   display: none;
// }